const hostLive = 'https://api.docexa.com/api/';
const hostStaging = 'https://api_staging.docexa.com/api/';



export default class Api {
    public static $prescriptionsavetos3bucket = `${hostStaging}v3/record/upload`;
    public static $prescriptionSave = `${hostStaging}v3/establishments/users`;
    public static $offlineSyncedPrescriptionSave =  `${hostStaging}v3/establishments/prescription/offlinesave`;

    public static $offlineStrokesSave = `${hostStaging}v3/strokes/save`;
    public static $getoffflineSavedStrokes = `${hostStaging}v3/strokes`;

    public static $getGeniePrescription = `${hostStaging}v3/genieprescription`;

    public static $uploadPrescription = `${hostStaging}v3/upload/precription`;

    public static $getTotalPrescription = `${hostStaging}v3/genieprescription`;

    public static $getPrescriptionByPatient = `${hostStaging}v3/establishments/users`;

    public static $getpatientById = `${hostStaging}v3/getpatientById`;

    public static $uploadOfflineSynckedPrescription = `${hostStaging}v3/upload/offlineprecription`;


    // login
    public static $login = `${hostStaging}v3/auth/login`
}
