import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Api from '../../Api';
import { Box, Button, IconButton, makeStyles, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { MdViewModule } from 'react-icons/md';
import Paper from '@mui/material/Paper';
import { FaEye } from 'react-icons/fa6';
import { Pagination } from '@mui/material';

// Define the type for each prescription item
interface Prescription {
    id: number;
    prescription_url: string;
    patient_id: number;
    patient_name: string;
    date: string;
}

const useStyle = makeStyles((theme) => ({
    table: {
        margin: '50px',
        width: '80%',
        marginBottom: ' 10px',
        marginLeft: '100px',
        minWidth: 800,
        '& th': {
            backgroundColor: 'rgb(131 193 255)',
            fontWeight: 'bold',
        },
        '& td': {
            padding: '10px',
        },
    },
    modalImage: {
        width: '80%',
        height: 'auto',
    },
    icon: {
        color: 'black',
        margin: '0px',
        fontSize: '24px',
        borderRadius: '50%'
    },
    iconButton: {
        borderRadius: '60%',
        padding: '15px'
    },

}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    //   bgcolor: 'white',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const ViewPrescriptionList = ({ usermapid, patientid }: { usermapid: string | undefined, patientid: string | undefined }) => {
    const classes = useStyle();
    const [prescriptionList, setPrescriptionList] = useState<any>([]);
    const [selectedPrescription, setSelectedPrescription] = useState<string | null>(null);
    const [isSetPrescription, setPrescriptionFlag] = useState<boolean>(false);
    const [patientData, setPatientData] = useState<any>([]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseOn = () => setOpen(false);


    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 7;

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {

        setCurrentPage(value);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedPrescriptions = prescriptionList.slice(startIndex, endIndex);

    const fetchPrescriptions = () => {
        axios.get(`${Api.$getPrescriptionByPatient}/${usermapid}/prescriptionbypatient/${patientid}`).then(
            response => {
                console.log('response in the fetch prescription', response);
                const prescriptions = response.data.data;
                const Prescription: any[] = [];
                prescriptions.forEach((prescription, index) => {
                    const generatedId = index + 1;
                    if (prescription.prescription_image) {
                        const prescriptionObject = {
                            id: index + 1,
                            prescription_url: prescription.prescription_image,
                            patient_id: prescription.patient_id,
                            prescription_created_date : prescription.created_at,
                        };
                        Prescription.push(prescriptionObject);
                    }
                });
                console.log('prescriptionImmagesss', Prescription);
                setPrescriptionList(Prescription);
                setPrescriptionFlag(true);
            })
            .catch(error => {
                console.log('error while fetch prescription', error);
            })

    };

    const handleClick = (url: string) => {
        console.log('hnnn', url);
        setSelectedPrescription(url);
        handleOpen(); // Open the modal when a prescription is selected
    };

    const onView = () => {
        fetchPrescriptions();
    };

    useEffect(() => {
        fetchPrescriptions();

        axios.get(`${Api.$getpatientById}/${patientid}`).then(
            response => {
                console.log('resss', response);
                setPatientData(response.data.data);
            }).catch(
                error => {
                    console.log('errorrr', error);
                }
            )
    }, [usermapid]);
    return (
        <div>
            <>
                {isSetPrescription && (
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align="center">View Prescription</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedPrescriptions.map((prescription, index) => (
                                    // {prescriptionList.map((prescription, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{prescription.id}</TableCell>
                                        {/* <TableCell>{patientData.patient_id}</TableCell> */}
                                        <TableCell>{patientData.patient_name}</TableCell>
                                        <TableCell>
                                            {new Date(prescription.prescription_created_date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                className={classes.iconButton}
                                                onClick={() => handleClick(prescription.prescription_url)}
                                            ><FaEye className={classes.icon} />  </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box mt={1} display="flex" justifyContent="center">
                            <Pagination
                                count={Math.ceil(prescriptionList.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </TableContainer>

                )}
            </>
            <Modal
                open={open}
                onClose={handleClose}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    {selectedPrescription && (
                        <img
                            src={selectedPrescription}
                            alt="Prescription"
                            className={classes.modalImage}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ViewPrescriptionList;
